import { useQuery } from '@tanstack/react-query';
import { BranchId } from '@yes/common-js';
import { config } from '../config/config';

interface ScheduleInterviewResponse {
  message: string; // e.g., 'Interview Scheduled'
  interviewId: number; // The ID of the scheduled interview
}

interface ScheduleInterviewParams {
  applicationId: number;
  branchId: BranchId;
  scheduleDate: EpochTimeStamp;
}

export const useScheduleInterview = ({
  applicationId,
  branchId,
  scheduleDate,
}: ScheduleInterviewParams) => {
  const { data, isLoading, error } = useQuery<ScheduleInterviewResponse>({
    queryKey: ['interview', branchId, applicationId, scheduleDate],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        config.SCHEDULE_APPOINTMENT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            applicationId,
            branchId,
            scheduleDate,
          }),
          signal,
        },
      );

      if (!response.ok) {
        throw new Error('Failed to create interview');
      }

      const schedules = await response.json();
      return schedules;
    },
    enabled: !!branchId && !!applicationId && !!scheduleDate, // Ensure all parameters are provided
    staleTime: 60000, // Cache data for 1 minute (adjust as needed)
    refetchOnWindowFocus: false, // Disable refetching when switching back to the tab
    refetchOnReconnect: false, // Disable refetching when reconnecting to the internet
    retry: false,
  });

  return { data, isLoading, error };
};
