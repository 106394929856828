import './not-found-screen.scss';

import HappyImage from '../../assets/images/happy.png';
import YesLogo from '../../assets/images/yes-logo.png';
import { useLocalization } from '../../context/LocalizationContext';

export const NotFoundScreen = () => {
    const { t } = useLocalization();

    return <div className="container">
        <img src={HappyImage} alt="group-png" className='happy' />
        <div className='message'>
            <img src={YesLogo} alt="yes-png" className='logo' />
            <p>{ t('general.msg.page_not_found') }</p>
        </div>
        <div className="background">
            <div className="polygon polygon-1"></div>
            <div className="polygon polygon-2"></div>
            <div className="polygon polygon-3"></div>
            <div className="polygon polygon-4"></div>
        </div>
    </div>
}