import React from 'react';
import './error-screen.scss'; // Make sure to include the CSS file
import { Button } from '../button/button';
import { useLocalization } from '../../context/LocalizationContext';

interface ErrorScreenProps {
  errorMessage: string;
}

const ErrorScreen: React.FC<ErrorScreenProps> = (p : ErrorScreenProps) => {
  const { errorMessage } = p; 
  const { t } = useLocalization();

  return (
    <div className="error-screen">
      <div className="error-content">
        <h1>{ t('general.msg.error') }</h1>
        <p>{errorMessage}</p>
        <Button colorSchema="secondary" onClick={() => window.location.reload()}>Retry</Button>
      </div>
    </div>
  );
};

export default ErrorScreen;