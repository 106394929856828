import React from 'react';
import './loading-screen.scss';
import { useLoading } from './loading-context';
import { useLocalization } from '../../context/LocalizationContext';

const GlobalLoadingScreen: React.FC = () => {
  const { isLoading } = useLoading();
  const { t } = useLocalization();

  if (!isLoading) return null; // Only render if loading is active

  return (
    <div className="loading-screen">
      <div className="spinner"></div>
      <p>{ t('general.msg.loading') }</p>
    </div>
  );
};

export default GlobalLoadingScreen;
