import { useSearchParams } from 'react-router-dom';
import { Calendar } from '../components/calendar/calendar';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { BranchCalendarSchedule } from './branch-calendar-schedule';
import './branch-calendar.scss';
import { useAvailability } from '../api';
import { AvailabilityWindow, AvailabilityDate } from '../types';
import ErrorScreen from '../components/error-screen/error-screen';
import { useLoading } from '../components/loading-screen/loading-context';
import { InterviewScheduledScreen } from './interview-scheduled';
import { useLocalization } from '../context/LocalizationContext';

const getNextValidDate = (): DateTime => {
  let nextDate = DateTime.now().plus({ days: 1 });

  // Check if the nextDate falls on a Sunday
  if (nextDate.weekday === 7) {
    nextDate = nextDate.plus({ days: 1 });
  }

  return nextDate;
};

export const BranchCalendarScreen = () => {
  const { t } = useLocalization();

  const [searchParams] = useSearchParams();
  const branchId = searchParams.get('branchId');
  const applicationId = searchParams.get('applicationId');
  
  const { showLoading, hideLoading } = useLoading();
  const [isScheduled, setIsScheduled] = useState(false); // Track if the interview is scheduled

  const { data: appointments, isLoading, error } = useAvailability(+branchId!);

  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(getNextValidDate());
  const [availableTimes, setAvailableTimes] = useState<AvailabilityWindow[]>([]);

  const handleOnInterviewScheduled = (isScheduled: boolean ) => {
    setIsScheduled(isScheduled)
  }

  const handleSelectDate = (date: DateTime) => {
    setSelectedDate(date); // Update selected date

    _availableDays.forEach((_date) => {
      const availableDateTime = DateTime.fromMillis(_date.day); // Convert _date.day (which is in milliseconds) to DateTime

      // Compare year, month, and day using `hasSame`
      const isSameDate = availableDateTime.hasSame(date, 'day');

      if (isSameDate) {
        // Matching date found for the availableDateTime!
        setAvailableTimes(_date.windows);
      }
    });
  };

  // Trigger handleSelectDate when appointments are loaded and selectedDate is set
  useEffect(() => {
    if (appointments && appointments.dates.length > 0 && selectedDate) {
      handleSelectDate(selectedDate);
    }
  }, [appointments, selectedDate]);

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading]);

  // Show an error message if the API call fails
  if (error) {
    return <ErrorScreen errorMessage={error.message} />;
  }

  // If no appointments are available
  if (!appointments || appointments.dates.length === 0) {
    return <p>{ t('branch_calendar.no_schedules') }</p>;
  }

  const _availableDays: AvailabilityDate[] = appointments.dates;

  // Validate and convert applicationId to a number
  const applicationIdNumber = Number(applicationId);

  if (!applicationId || isNaN(applicationIdNumber)) {
    return <ErrorScreen errorMessage={ t('branch_calendar.error.invalid_application_id') }/>
  }

  // Validate and convert branchId to a number
  const branchIdNumber = Number(branchId);

  if (!branchId || isNaN(branchIdNumber)) {
    return <ErrorScreen errorMessage={ t('branch_calendar.error.invalid_branch_id') }/>
  }

  // Show confirmation message if the interview is scheduled
  if (isScheduled) {
    return <InterviewScheduledScreen />
  }
    

  return (
    <div className="calendar-component">
      <div className="header">
        {t('branch_calendar.title')}
      </div>
      <div className="body">
        <Calendar
          selectedDate={selectedDate}
          onSelectDate={handleSelectDate}
          availableDays={_availableDays}
        />
        <BranchCalendarSchedule
          selectedDate={selectedDate}
          availableTimes={availableTimes}
          applicationId={applicationIdNumber}
          branchId={branchIdNumber}
          onInterviewScheduled={handleOnInterviewScheduled}
        />
      </div>
    </div>
  );
};
