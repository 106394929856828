import { useEffect, useState, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Button } from '../components/button/button';

import './branch-calendar-schedule.scss';
import { AvailabilityWindow } from '../types';
import { useScheduleInterview } from '../api';
import ErrorScreen from '../components/error-screen/error-screen';
import { useLoading } from '../components/loading-screen/loading-context';
import './branch-calendar-schedule.scss';
import { useLocalization } from '../context/LocalizationContext';
interface BranchSchedulesProps {
  selectedDate?: DateTime;
  availableTimes: AvailabilityWindow[];
  applicationId: number;
  branchId: number;
  onInterviewScheduled: (isScheduled: boolean) => void;
}

export const BranchCalendarSchedule = ({
  selectedDate,
  availableTimes,
  applicationId,
  branchId,
  onInterviewScheduled,
}: BranchSchedulesProps) => {
  const { t } = useLocalization();

  const { showLoading, hideLoading } = useLoading();
  const [visibleSlots, setVisibleSlots] = useState(6); // Show 6 slots initially
  const [selectedSlotIndex, setSelectedSlotIndex] = useState<
    number | undefined
  >();

  const [scheduleParams, setScheduleParams] = useState<
    | {
        applicationId: number;
        branchId: number;
        scheduleDate: EpochTimeStamp;
      }
    | undefined
  >();

  const {
    data: interviewData,
    isLoading,
    error,
  } = useScheduleInterview(
    scheduleParams ?? {
      applicationId: 0,
      branchId: 0,
      scheduleDate: DateTime.now().toMillis(),
    },
  );

  // Reset slot index and visible slots when the selected date changes
  useEffect(() => {
    setSelectedSlotIndex(undefined);
    setVisibleSlots(6);
  }, [selectedDate]);

  // Show or hide loading screen based on `isLoading` state
  useEffect(() => {
    isLoading ? showLoading() : hideLoading();
  }, [isLoading, showLoading, hideLoading]);

  // Handle interview scheduling success
  useEffect(() => {
    if (interviewData) {
      onInterviewScheduled(true); // Mark as scheduled to hide the screen content
    }
  }, [interviewData]);

  // Memoize the formatted date to avoid unnecessary re-computation
  const formattedDate = useMemo(
    () => selectedDate?.toFormat("cccc d LLLL 'at' 'GMT'ZZ") ?? '',
    [selectedDate],
  );

  // Handle slot selection
  const handleSelectSlot = useCallback(
    (time: AvailabilityWindow, index: number) => {
      setSelectedSlotIndex(index);
    },
    [],
  );

  // Handle interview confirmation and scheduling
  const handleConfirm = useCallback(
    (time: AvailabilityWindow) => {
      const scheduleDate = time.startTime;
      setScheduleParams({ applicationId, branchId, scheduleDate });
    },
    [applicationId, branchId],
  );

  // Render a single slot (modularizing the render logic)
  const renderSlot = (time: AvailabilityWindow, index: number) => (
    <div className="slotTime" key={index}>
      <Button
        colorSchema="secondary"
        onClick={() => handleSelectSlot(time, index)}
        style={{ width: '100%' }}
        disabled={!time.available}
      >
        {DateTime.fromMillis(time.startTime).toFormat('HH:mm')}
      </Button>
      {selectedSlotIndex === index && (
        <Button colorSchema="primary" onClick={() => handleConfirm(time)}>
          Confirm
        </Button>
      )}
    </div>
  );

  // Handle "Show More" functionality
  const handleShowMore = useCallback(() => {
    setVisibleSlots((prev) => prev + 6);
  }, []);

  // Error handling
  if (error) {
    return <ErrorScreen errorMessage={error.message} />;
  }

  // No selected date
  if (!selectedDate) return null;

  return (
    <div className="branch-schedules">
      <span className="current-date">{formattedDate}</span>
      <span
        className={`title ${
          availableTimes.length === 0 ? 'no-time-title' : ''
        }`}
      >
        {availableTimes.length > 0
          ? t('branch_calendar_schedule.available_times')
          : t('branch_calendar_schedule.no_available_times')}
      </span>
      <div className="slots">
        {availableTimes.slice(0, visibleSlots).map(renderSlot)}
      </div>
      {visibleSlots < availableTimes.length && (
        <div className="show-more">
          <Button colorSchema="info" onClick={handleShowMore}>
            {t('general.buttons.show_more')}
          </Button>
        </div>
      )}
    </div>
  );
};
